<template>
	<div class="ui-power">
		<a-spin :spinning="loading">
			<Header title="编辑角色权限" @back="onBack(false)"></Header>
			<h4 class="ui-head">正在编辑角色：{{ $route.query.name }}</h4>
			<a-divider orientation="left">编辑角色权限</a-divider>
			<div v-for="item in allMenus" :key="item.url" v-permission="[item.url]">
				<div>
					<a-checkbox :checked="checkedUrls.includes(item.url)" @change="(e) => onAllChange(e, item)">
						<h2 class="ui-title">
							{{ item.title }}
						</h2>
					</a-checkbox>
				</div>
				<a-row class="ui-subBox" v-for="subItem in item.children" :key="subItem.url"
					v-permission="[subItem.url]">
					<div>
						<div class="ui-text">
							<a-checkbox :checked="checkedUrls.includes(subItem.url)"
								@change="(e) => onSubAllChange(e, subItem, item)">
								<div>
									{{ subItem.title }}
								</div>
							</a-checkbox>
							<a-button v-if="subItem.children && subItem.children.length" @click.stop="(e) => onSubAllChange2(e, subItem, item)" size="small">全选</a-button>
						</div>
						<div>
							<div class="ui-subBox" v-for="thirdItem in subItem.children" :key="thirdItem.url" v-permission="[thirdItem.url]">
								<div>
									<a-checkbox :checked="checkedUrls.includes(thirdItem.url)"
										@change="(e) => onItemChange(e, thirdItem, subItem, item)"
									>
										<div class="ui-text2">
											{{ thirdItem.title }}
										</div>
									</a-checkbox>
									<a-button v-if="thirdItem.children && thirdItem.children.length" @click.stop="(e) => onThirdAllChange2(e, thirdItem, subItem, item)" size="small">全选</a-button>
								</div>
								<div class="ui-subBox">
									<a-checkbox class="ui-third" :checked="checkedUrls.includes(fourthItem.url)"
										@change="(e) => onFourthItemChange(e,fourthItem ,thirdItem, subItem, item)"
										v-for="fourthItem in thirdItem.children" :key="fourthItem.url"
										v-permission="[fourthItem.url]">
										<div class="ui-text3">
											{{ fourthItem.title }}
										</div>
									</a-checkbox>
								</div>
							</div>
						</div>
					</div>
					<a-divider style="margin: 10px 0 0;" dashed />
				</a-row>
				<a-divider />
			</div>
			<div style="margin-left: 100px;">
				<a-button type="primary" @click="onSubmit">提交修改</a-button>
			</div>
		</a-spin>
	</div>
</template>

<script>
	import Header from '@/components/header/header.vue';
	import * as systemApi from '@/service/modules/system';
	export default {
		components: {
			Header
		},
		props: {
			id: {
				type: Number,
				default: 0
			},
			name: {
				type: String,
				default: ''
			}
		},
		data() {
			return {
				loading: false,
				allMenus: [],
				checkedUrls: []
			}
		},
		created() {
			if (this.id) {
				this.getData();
			}
		},
		methods: {
			onBack() {
				this.$emit('back', false);
			},
			onSubmit() {
				const hasUrls = this.$store.state.system.permissionCodes;
				let urls = [];
				this.checkedUrls.forEach(item => {
					if (hasUrls.includes(item)) {
						urls.push(item);
					}
				})
				this.loading = true;
				systemApi.setRoleMenu({
					id: this.id,
					menuUrls: urls.join(',')
				}).then(res => {
					this.loading = false;
					if (res.code == 200) {
						this.$message.success('操作成功');
						this.onBack();
					}
				})
			},
			getData() {
				this.loading = true;
				systemApi.getRoleMenu({
					id: this.id
				}).then(res => {
					this.loading = false;
					if (res.code == 200) {
						let allMenu = res.data.allMenus.filter(item => {
							item.powerCode = [];
							item.children = res.data.allMenus.filter(subItem => {
								if (subItem.parents === item.url) {
									item.powerCode.push(subItem.url);
								}
								return subItem.parents === item.url;
							})
							return item.isParent
						});
						this.allMenus = allMenu;
						this.checkedUrls = res.data.selectedMenus.map(item => {
							return item.url;
						})
					}
				})
			},
			onAllChange(e, data) {
				let checked = e.target.checked;
				let allUrls = data.powerCode;
				data.children.forEach(item => {
					allUrls = allUrls.concat(item.powerCode);
					item.children.forEach(subItem => {
						allUrls = allUrls.concat(subItem.powerCode);
					})
				});
				if (checked) {
					allUrls.forEach(itemUrl => {
						if (!this.checkedUrls.includes(itemUrl) && this.checkPermission(itemUrl)) {
							this.checkedUrls.push(itemUrl);
						}
					});
					this.checkedUrls.push(data.url);
				} else {
					allUrls.forEach(itemUrl => {
						if (this.checkedUrls.includes(itemUrl) && this.checkPermission(itemUrl)) {
							this.checkedUrls.splice(this.checkedUrls.indexOf(itemUrl), 1);
						}
					});
					this.checkedUrls.splice(this.checkedUrls.indexOf(data.url), 1);
				}
			},
			onSubAllChange(e, data, itemData) {
				let checked = e.target.checked;
				let allUrls = data.powerCode;
				if (checked && this.checkPermission(data.url)) {
					if (data.parents && !this.checkedUrls.includes(data.parents) && this.checkPermission(data.parents)) {
						this.checkedUrls.push(data.parents);
					}
					this.checkedUrls.push(data.url);
					// allUrls.forEach(itemUrl => {
					//     if (!this.checkedUrls.includes(itemUrl)) {
					//         this.checkedUrls.push(itemUrl);
					//     }
					// });
				} else {
					allUrls.forEach(itemUrl => {
						if (this.checkedUrls.includes(itemUrl)) {
							this.checkedUrls.splice(this.checkedUrls.indexOf(itemUrl), 1);
						}
					});
					if (data.children && data.children.length) {
						this.deleteChildrenId(data.children);
					}
					this.checkedUrls.splice(this.checkedUrls.indexOf(data.url), 1);
					let canItemDel = true;
					for (let i = 0; i < itemData.powerCode.length; i++) {
						let url = itemData.powerCode[i];
						if (this.checkedUrls.includes(url)) {
							canItemDel = false;
							break;
						}
					}
					if (canItemDel) {
						this.checkedUrls.splice(this.checkedUrls.indexOf(itemData.url), 1);
					}
				}
			},
			onSubAllChange2(e, data) {
				let allUrls = data.powerCode;
				if (data.parents && !this.checkedUrls.includes(data.parents) && this.checkPermission(data.parents)) {
					this.checkedUrls.push(data.parents);
				}
				data.children.forEach(item => {
					allUrls = allUrls.concat(item.powerCode);
				})
				if (data.url && !this.checkedUrls.includes(data.url) && this.checkPermission(data.url)) {
					this.checkedUrls.push(data.url);
				}
				allUrls.forEach(itemUrl => {
					if (!this.checkedUrls.includes(itemUrl) && this.checkPermission(itemUrl)) {
						this.checkedUrls.push(itemUrl);
					}
				});
			},
			onThirdAllChange2(e, data, parentData) {
				let allUrls = data.powerCode;
				if (data.parents && !this.checkedUrls.includes(data.parents) && this.checkPermission(data.parents)) {
					this.checkedUrls.push(data.parents);
				}
				if (parentData.parents && !this.checkedUrls.includes(parentData.parents) && this.checkPermission(parentData.parents)) {
					this.checkedUrls.push(parentData.parents);
				}
				if (data.url && !this.checkedUrls.includes(data.url) && this.checkPermission(data.url)) {
					this.checkedUrls.push(data.url);
				}
				allUrls.forEach(itemUrl => {
					if (!this.checkedUrls.includes(itemUrl) && this.checkPermission(itemUrl)) {
						this.checkedUrls.push(itemUrl);
					}
				});
			},
			onItemChange(e, thirdData, subData, itemData) {
				let checked = e.target.checked;
				if (checked) {
					if (itemData.parents && !this.checkedUrls.includes(itemData.parents)) {
						this.checkedUrls.push(itemData.parents);
					}
					if (subData.parents && !this.checkedUrls.includes(subData.parents)) {
						this.checkedUrls.push(subData.parents);
					}
					if (thirdData.parents && !this.checkedUrls.includes(thirdData.parents)) {
						this.checkedUrls.push(thirdData.parents);
					}
					this.checkedUrls.push(thirdData.url);
				} else {
					this.checkedUrls.splice(this.checkedUrls.indexOf(thirdData.url), 1);
					let canSubDel = true;
					for (let i = 0; i < subData.powerCode.length; i++) {
						let url = subData.powerCode[i];
						if (this.checkedUrls.includes(url)) {
							canSubDel = false;
							break;
						}
					}
					if (canSubDel) {
						this.checkedUrls.splice(this.checkedUrls.indexOf(subData.url), 1);
					}
					let canItemDel = true;
					for (let i = 0; i < itemData.powerCode.length; i++) {
						let url = itemData.powerCode[i];
						if (this.checkedUrls.includes(url)) {
							canItemDel = false;
							break;
						}
					}
					if (canItemDel) {
						this.checkedUrls.splice(this.checkedUrls.indexOf(itemData.url), 1);
					}
					if (thirdData.children && thirdData.children.length) {
						this.deleteChildrenId(thirdData.children);
					}
				}
			},
			onFourthItemChange(e, fourthData, thirdData, subData, itemData) {
				let checked = e.target.checked;
				if (checked) {
					if (itemData.parents && !this.checkedUrls.includes(itemData.parents)) {
						this.checkedUrls.push(itemData.parents);
					}
					if (subData.parents && !this.checkedUrls.includes(subData.parents)) {
						this.checkedUrls.push(subData.parents);
					}
					if (thirdData.parents && !this.checkedUrls.includes(thirdData.parents)) {
						this.checkedUrls.push(thirdData.parents);
					}
					if (fourthData.parents && !this.checkedUrls.includes(fourthData.parents)) {
						this.checkedUrls.push(fourthData.parents);
					}
					this.checkedUrls.push(fourthData.url);
				} else {
					this.checkedUrls.splice(this.checkedUrls.indexOf(fourthData.url), 1);
					let canThirdDel = true;
					for (let i = 0; i < thirdData.powerCode.length; i++) {
						let url = thirdData.powerCode[i];
						if (this.checkedUrls.includes(url)) {
							canThirdDel = false;
							break;
						}
					}
					if (canThirdDel) {
						this.checkedUrls.splice(this.checkedUrls.indexOf(thirdData.url), 1);
					}
					let canSubDel = true;
					for (let i = 0; i < subData.powerCode.length; i++) {
						let url = subData.powerCode[i];
						if (this.checkedUrls.includes(url)) {
							canSubDel = false;
							break;
						}
					}
					if (canSubDel) {
						this.checkedUrls.splice(this.checkedUrls.indexOf(subData.url), 1);
					}
					let canItemDel = true;
					for (let i = 0; i < itemData.powerCode.length; i++) {
						let url = itemData.powerCode[i];
						if (this.checkedUrls.includes(url)) {
							canItemDel = false;
							break;
						}
					}
					if (canItemDel) {
						this.checkedUrls.splice(this.checkedUrls.indexOf(itemData.url), 1);
					}
				}
			},
			deleteChildrenId(data) {
				data.forEach(item => {
					let i = this.checkedUrls.indexOf(item.url);
					if (i !== -1) {
						this.checkedUrls.splice(i, 1);
					}
					if (item.children && item.children.length) {
						this.deleteChildrenId(item.children);
					}
				})
			},
			checkPermission(code) {
				const permissionCodes = this.$store.state.system.permissionCodes;
				const r = permissionCodes.includes(code);
				return r;
			}
		}
	}
</script>

<style scoped>
	.ui-power {
		text-align: left;
	}

	.ui-head {
		margin-top: 18px;
		font-size: 17px;
		font-weight: 700;
	}

	.ui-title {
		font-size: 15px;
		font-weight: 700;
	}

	.ui-text {
		width: 180px;
		font-weight: 600;
	}

	.ui-text2 {
		/* width: 150px; */
		font-weight: 500;
	}
	.ui-text3 {
		width: 150px;
		font-weight: 500;
	}

	.ui-subBox {
		margin-top: 20px;
		margin-left: 30px;
	}
	.ui-third {
		margin-bottom: 10px;
		margin-left: 0;
	}
</style>
